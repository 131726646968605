import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { StyledGridRow } from "../../commons/Grid";

import { 
  StyledGatsbyImage,
  StyledGatsbyImageDesktop,
  StyledGatsbyImageMobile,
} from "./style";

const GatsbyImageBlock = ({
  image, imageMobile, backgroundColor, isFullWidth, allImages, 
}) => {
  const imageData = allImages.find(e => e.path === image);
  const imageDataMobile = allImages.find(e => e.path === imageMobile) ?? imageData;
  
  return (
    <StyledGatsbyImage backgroundColor={backgroundColor}>
      <StyledGridRow noMargin isFull={isFullWidth}>
        <StyledGatsbyImageDesktop>
          <GatsbyImage image={getImage(imageData)} alt={imageData.alt} />
        </StyledGatsbyImageDesktop>
        <StyledGatsbyImageMobile>
          <GatsbyImage image={getImage(imageDataMobile)} alt={imageDataMobile.alt} />
        </StyledGatsbyImageMobile>
      </StyledGridRow>
    </StyledGatsbyImage>
  );
};

export default GatsbyImageBlock;
